import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const refundsSlice = createSlice({
	name: 'refunds',
	initialState,
	reducers: {
		setRefundsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		updateRefundItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		addRefundsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeRefundsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setRefundsLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setRefundsList, addRefundsItem, removeRefundsItem, setRefundsLoading, updateRefundItem } = refundsSlice.actions;

export const getRefundsList = (sendData = {}) => async (dispatch) => {

	dispatch(setRefundsLoading(true));

	try {
		const res = await api.refundsList(sendData);
		dispatch(setRefundsList(res));

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с получение списка запросов на возврат'
		}));

	} finally {

		dispatch(setRefundsLoading(false));

	}

};

export const getRefundById = (id) => async ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {

		const state = getState();

		const list = state.refunds.list;

		let item = list.find(item => String(item.id) === String(id));

		if (item) {

			resolve(item);

		} else {

			api.refundById(id)
				.then(res => {
					resolve(res);
					dispatch(addRefundsItem(res));
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Заявка ${id} не найдена`
					}));

					reject();

				});
		}

	});

};

export const reloadRefund = (refundId) => async (dispatch) => {

	try {

		const refund = await api.refundById(refundId);

		if (refund) {
			dispatch((updateRefundItem(refund)));
		}

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось обновить публикацию'
		}));

	}

};


export const markRefund = ( refundId, status ) => async (dispatch) => {

	dispatch(setRefundsLoading(true));

	try {

		await api.markRefundAsPaid( refundId, status );
		dispatch(reloadRefund( refundId ));

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с подтверждением'
		}));

	} finally {

		dispatch(setRefundsLoading(false));

	}

};

export default refundsSlice.reducer;